<template>
  <h1></h1>
</template>

<script>
import { useRoute } from "vue-router";
import peaceApp from "../../main";
import { manualInvoiceConfig } from "../../services/service.js";
import { manualAWBConfig,c2PAWBConfig } from "../../services/service-AWB.js";

export default {
  name: "QrpayEntry",
  mounted() {
    const route = useRoute();
    const [method] = [...route.params.pathMatch];
    const countryCode = route.params.countryInfo;

	const locationCodesMap = new Map();
	locationCodesMap.set("TH", process.env.VUE_APP_LOCATION_CODES_TH.split(","));
	locationCodesMap.set("HK", process.env.VUE_APP_LOCATION_CODES_HK.split(","));
	locationCodesMap.set("PH", process.env.VUE_APP_LOCATION_CODES_PH.split(","));
	locationCodesMap.set("SG", process.env.VUE_APP_LOCATION_CODES_SG.split(","));
	locationCodesMap.set("MY", process.env.VUE_APP_LOCATION_CODES_MY.split(","));
	locationCodesMap.set("MO", process.env.VUE_APP_LOCATION_CODES_MO.split(","));
	
    const locationCodes =  locationCodesMap.get(countryCode.toUpperCase()) || [];
    const stationId = (locationCodes.length >0 && route.query['s'] !== "")? route.query['s'] : null;
    const paymentId = (locationCodes.length >0 && route.query['paymentId'] !== "")? route.query['paymentId'] : null;
    const lang = route.params.locale;
    let uri = route.fullPath;
    uri = uri.replace(`/QRpay`, "");
    uri = uri.replace(`/${countryCode}`, "");
    if (lang) {
      uri = uri.replace(`/${lang}`, "");
    }
    peaceApp.config.globalProperties.$countryCode = countryCode;
    this.$i18n.locale = lang || "en";
    const inURL = localStorage.getItem("inURL");
    const supportedInvoiceMethods = ["I", "S2", "D2"];
    // sessionStorage.clear();
    if (method === "AM") {
      if(locationCodes.length > 0 && (!locationCodes.includes(stationId) || stationId === null)){
        this.$router.push({
            name: "ErrorInfo",
       });
       return false;
      } else if(locationCodes.length === 0 && (route.query['s'] || route.query['s'] === "")){
        this.$router.push({
            name: "ErrorInfo",
       });
       return false;
      }
      manualAWBConfig(this.$countryCode, "", this.$i18n.locale)
        .then((res) => {
          const routerQueryParams = {
            lang: res.language,
            countryCode: res.country,
            currency: res.currency,
            method: res.type,
            stationId,
          };
          localStorage.setItem("inURL", inURL);
          sessionStorage.setItem(
            "routerQueryParams",
            JSON.stringify(routerQueryParams)
          );
          sessionStorage.setItem("countryCode", countryCode);
          sessionStorage.setItem("formatedPayDate", res.formatedPayDate);
          const data = {
            entryFrom: "manualAWB",
          };
          this.$router.push({
            name: "PayAWB",
            query: { ...data },
          });
        })
        .catch((err) => {
          this.$router.push({
            name: "ErrorInfo",
          });
          console.log(err);
        });
    } else if (method === "AQ") {
      if(locationCodes.length > 0 && (!locationCodes.includes(stationId) || stationId === null)){
        this.$router.push({
            name: "ErrorInfo"
       });
       return false;
      } else if(locationCodes.length === 0 && (route.query['s'] || route.query['s'] === "")){
        this.$router.push({
            name: "ErrorInfo",
       });
       return false;
      }
      manualAWBConfig(this.$countryCode, uri, this.$i18n.locale)
        .then((res) => {
          const routerQueryParams = {
            statementDate: res.payDate,
            method: res.type,
            awbItems: res.awbItems,
            countryCode: res.country,
            company: res.company,
            lang: res.language,
            currency: res.currency,
            versionQR: res.versionQR,
            stationId,
          };
          localStorage.setItem("inURL", inURL);
          sessionStorage.setItem(
            "routerQueryParams",
            JSON.stringify(routerQueryParams)
          );
          sessionStorage.setItem("countryCode", countryCode);
          sessionStorage.setItem("formatedPayDate", res.formatedPayDate);
          this.$router.push({
            name: "QRAWBPayment",
          });
        })
        .catch((err) => {
          this.$router.push({
            name: "ErrorInfo",
          });
          console.log(err);
        });
    } else if(method === "AC"){
      if(locationCodes.length > 0 && (route.query['paymentId']===null || route.query['paymentId'] === "")){
        this.$router.push({
            name: "ErrorInfo",
       });
       return false;
      }
      c2PAWBConfig(this.$countryCode, paymentId,this.$i18n.locale).then((res)=>{
        const routerQueryParams = {
            lang: res.locale.substring(0,2),
            countryCode: res.countryCode,
            currency: res.payableCurrency,
            method: res.type,
            stationId:res.destinationLocationId,
            paymentId:paymentId
          };
          localStorage.setItem("inURL", inURL);
          sessionStorage.setItem(
            "routerQueryParams",
            JSON.stringify(routerQueryParams)
          );
          sessionStorage.setItem("countryCode", countryCode);
          sessionStorage.setItem("emailAddress",res.emailAddress)
          sessionStorage.setItem("payMethodType",res.type);
          sessionStorage.setItem("formatedPayDate", res.formatedPayDate);
          sessionStorage.setItem("callbackUrl",res.callbackUrl);
        const billInfo = {
          company:res.companyName,
          billDate:res.payDate,
          type:res.type
        }

        const billList=[];
        const newItem = {
            awbno: res.awbNumber,
            manuallyAdded: true,
            amount: res.payableAmount.toString(),
            error: false,
            payFlag: false,
          }
          billList.push(newItem);
        sessionStorage.setItem("billInfo",JSON.stringify(billInfo));
        sessionStorage.setItem("billPay",JSON.stringify(billList));
        this.$router.push("/ConfirmAWBPayment")
      }).catch((err) => {
          this.$router.push({
            name: "ErrorInfo",
          });
          console.log(err);
        });
    } else if (supportedInvoiceMethods.includes(method)) {
      manualInvoiceConfig(this.$countryCode, uri, this.$i18n.locale)
        .then((res) => {
          const routerQueryParams = {
            lang: res.language,
            countryCode: res.country,
            currency: res.currency,
            company: res.company,
            statementDate: res.formatedDate,
            method: res.type,
            versionQR: res.versionQR,
            invoiceItems: res.invoiceItems,
          };
          localStorage.setItem("inURL", inURL);
          sessionStorage.setItem(
            "routerQueryParams",
            JSON.stringify(routerQueryParams)
          );
          sessionStorage.setItem("countryCode", countryCode);
          sessionStorage.setItem("formatedPayDate", res.formatedPayDate);
          if (res.invoiceItems.length > 0) {
            this.$router.push({
              name: "SelectInvoice",
            });
          }
        })
        .catch((err) => {
          this.$router.push({
            name: "ErrorInfo",
          });
          console.log(err);
        });
    } else {
      manualInvoiceConfig(this.$countryCode, "", this.$i18n.locale)
        .then((res) => {
          const routerQueryParams = {
            lang: res.language,
            countryCode: res.country,
            currency: res.currency,
          };
          localStorage.setItem("inURL", inURL);
          sessionStorage.setItem(
            "routerQueryParams",
            JSON.stringify(routerQueryParams)
          );
          sessionStorage.setItem("countryCode", countryCode);
          sessionStorage.setItem("formatedPayDate", res.formatedPayDate);
          const data = {
            entryFrom: "manualInvoice",
          };
          this.$router.push({
            name: "PayInvoice",
            query: { ...data },
          });
        })
        .catch((err) => {
          this.$router.push({
            name: "ErrorInfo",
          });
          console.log(err);
        });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
