<template>
  <div class="loader-section" :class="$attrs.class">
    <div class="loader-section-body">
      <h1 class="loading-text">{{ $t("messages.globalPayment.loading") }}</h1>
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style>
.loader-section {
  position: fixed;
  text-align: center;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: #ffffff;
  opacity: 0.8;
}
.c2p-loader-section{
  opacity: 1;
}
.loader-section-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-text {
  font-size: 18px;
  font-weight: bolder;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(11, 11, 12);
  border-color: #4d148c transparent #4d148c transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>