<template>
  <template v-if="payType!='AC'"  >
    <div class="payment-status-container container">
    <h1>{{ statusHead(paymentStatus) }}</h1>
    <p>
      {{ $t(`messages.bill.to`) }}: {{ company }}<br />
      {{ statusPay(paymentStatus) }}:&nbsp;{{ currency }}
      <span v-if="totalAmountToPay != ''">{{ totalAmountToPay }} </span><br />
      {{ $t(`messages.bill.trandate`) }}:&nbsp;{{ payDate }}<br />
      {{ $t(`messages.bill.payref`) }}:&nbsp;{{ payRef }}
    </p>
    <p class="info" v-bind:class="{ error_msg: paymentStatus !== 'success' }" v-html="statusText(paymentStatus)">
      </p>
      <BillTable
        :totalamount="totalAmountPaidUnPaid"
        :colamountheader="colAmountHead"
        :billPay="invoicesList"
        :statusinfo="$t(`messages.awb.success.total`)"
        :currency="currency"
        :country="countryCode"
        :awb="true"
      ></BillTable>
      <hr />
    </div>
  </template>
  <template v-else>
        <LoadingSpinner class="c2p-loader-section" v-if="showLoading" />
  </template>
</template>
<script>
import { useRoute } from "vue-router";
import { transactionDetail } from "../../services/service.js";
import BillTable from "../shared/BillTable.vue";
import LoadingSpinner from "../shared/LoadingSpinner.vue";
export default {
  name: "PaymentStatus",
  data: function () {
    return {
      paymentStatus: "",
      company: "",
      currency: "",
      totalAmountToPay: "",
      payDate: "",
      totalAmountPaidUnPaid: "",
      colAmountHead: "",
      invoicesList: [],
      countryCode: "",
      locale: "",
      payRef: "",
      showLoading:false,
      payType:sessionStorage.getItem("payMethodType")
    };
  },
  components: {
    BillTable,
    LoadingSpinner
  },
  mounted() {
    const route = useRoute();
    const [paymentStatus] = route.params.pathMatch;
    this.paymentStatus = paymentStatus;
    this.countryCode = route.params.countryInfo;
    console.log("Country code====> ",this.countryCode);
    this.locale = route.params.locale;
    console.log("LOCALE====> ",this.locale);
    this.$i18n.locale = this.locale;
    const { payRef } = route.query;
    this.payRef = payRef;

    transactionDetail(this.countryCode, payRef, this.locale)
      .then((res) => {
        if(res.c2pCallbackUrl){
          this.showLoading = true;
          // let navigateURL=`${res.c2pCallbackUrl}&country=${res.country}&locale=${res.language}-${res.country}&linkId=${res.c2pPaymentId}&source=dops&paymentActivityId=0`;
          let navigateURL=`${res.c2pCallbackUrl}/${res.language}-${res.country}/${res.c2pPaymentId}/fetching-status`;          
          window.location.replace(navigateURL);
        }      
        (this.company = res.company),
          (this.currency = this.$t(`messages['${res.currency}']`)),
          (this.payDate = res.transactionDate);
        this.totalAmountToPay = this.$filter.changeAmountFormate(
          res.total,
          this.countryCode
        );
        this.invoicesList = res.awbItems;
        (this.totalAmountPaidUnPaid = res.total),
          (this.payDate = res.formatedPayDate),
          this.colHead(paymentStatus);      
      })
      .catch((err) => {
        if (err.response?.status === 404) this.$router.push("/Error");        
        else if (err.response?.status === 401) {
          this.company = JSON.parse(sessionStorage.getItem('billInfo')).company;
          this.currency = JSON.parse(sessionStorage.getItem('routerQueryParams')).currency;
          this.payDate = sessionStorage.getItem('formatedPayDate');
          this.totalAmountToPay = this.$filter.changeAmountFormate(
            +sessionStorage.getItem('totalAmountToPay'),
            this.countryCode
          );
          this.invoicesList = JSON.parse(sessionStorage.getItem('billPay'));
          this.totalAmountPaidUnPaid = sessionStorage.getItem('totalAmountToPay');
          this.colHead(paymentStatus);
        }
        else if (err.response) console.log(err.response);
        else this.$router.push("/Error");
      });
  },

  methods: {
    /**
     * filter status head
     * @returns {void}
     */
    statusHead: function (value) {
      if (value == "cancel") {
        return this.$t(`messages.cancel.head`);
      } else if (value == "fail") {
        return this.$t(`messages.fail.head`);
      } else if (value == "success") {
        return this.$t(`messages.success.head`);
      } else return "";
    },

    /**
     * filter status text
     * @returns {void}
     */
    statusText: function (value) {
      if (value == "cancel") {
        return this.$t(`messages.awb.cancel.text`);
      } else if (value == "fail") {
        return this.$t(`messages.awb.fail.text`);
      } else if (value == "success") {
        return this.$t(`messages.awb.success.text`);
      } else return "";
    },

    /**
     * filter status pay
     * @returns {void}
     */
    statusPay: function (value) {
      if (value == "success") {
        return this.$t(`messages.awb.success.paid`);
      } else return this.$t(`messages.awb.paytotal`);
    },

    /**
     * filter status total
     * @returns {void}
     */
    statusTotal: function (value) {
      if (value == "cancel") {
        return this.$t(`messages.invoice.total`);
      } else if (value == "fail") {
        return this.$t(`messages.invoice.total`);
      } else if (value == "success") {
        return this.$t(`messages.success.total`);
      } else return "";
    },

    /**
     * set column head
     * @returns {string}
     */
    colHead: function (value) {
      if (value == "cancel" || value == "fail") {
        this.totalAmountPaidUnPaid = this.totalAmountToPay;
        this.totalAmountToPay = this.$filter.changeAmountFormate(
          0,
          this.countryCode
        );
        this.colAmountHead = this.$t(`messages.awb.unpaid`);
        return this.$t(`messages.awb.total`);
      } else if (value == "success") {
        this.totalAmountPaidUnPaid = this.totalAmountToPay;
        this.colAmountHead = this.$t(`messages.awb.paid`);
      } else return "";
    },
  },
};
</script>
<style scoped>
p {
  margin: 0 !important;
}
p.info {
    padding: 10px 0 10px 0;
}
.payment-status-container {
  text-align: left;
  color: #000;
}
.payment-status-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
.error_msg {
  color: red;
}
.error_border {
  border-color: red;
}
</style>
