<template>
  <LoadingSpinner v-if="showLoading" />
  <div class="CC-card_holder">
    <h1>{{ $t("messages.globalPayment.head") }}</h1>
    <div class="payment-info">
      <p>{{ $t("messages.globalPayment.refernceNumber") }}: {{ payRef }}</p>
      <p>
        {{ $t("messages.globalPayment.amount") }}:
        {{ $t(`messages['${$filter.getCurrency($countryCode)}']`) }}
        {{ $filter.changeAmountFormate(totalAmout, $countryCode) }}
      </p>
    </div>
    <div class="required-info">
      <p v-html="$t(`messages.entry.required`)"></p>
    </div>
    <div v-if="!challageRequired">
      <form id="payment-form" method="post" action="process_payment">
        <div class="card-header-section">
          <p v-html="$t(`messages.globalPayment.ccTitle`)" class="card-header-label"></p>
          <div class="log-section">
            <div class="card-logo visa">
              <img
                src="../../assets/Visa-Logo-PNG3.png"
                alt="Visa Card"
                width="60"
                height="35"
              />
            </div>
            <div class="card-logo mastercard">
              <img
                src="../../assets/Mastercard-Logo-PNG10.png"
                alt="Visa Card"
                width="65"
                height="50"
              />
            </div>
          </div>
        </div>
        <label for="card-number" v-html="$t(`messages.globalPayment.cardNumber`)"></label>
        <div id="card-number"></div>
        <p v-if="this.cardError" class="error_msg" v-html="$t('messages.globalPayment.invalidCardNumber')"></p>
        <div class="date-cvv">
          <div class="cc-expiration">
            <label for="card-expiration" v-html="$t(`messages.globalPayment.cardExpiration`)"></label>
            <div id="card-expiration"></div>
            <p v-if="this.cardExpirationError" class="error_msg" v-html="$t('messages.globalPayment.invalidExpiryData')"></p>
          </div>
          <div class="cc-csv">
            <label for="card-cvv" v-html="$t(`messages.globalPayment.cardCVV`)"></label>
            <div id="card-cvv"></div>
            <p v-if="this.cvvError" class="error_msg" v-html="$t('messages.globalPayment.invalidCVVNumber')"></p>
          </div>
        </div>

        <label for="card-holder-name" v-html="$t(`messages.globalPayment.cardHolderName`)"></label>
        <div id="card-holder-name"></div>
        <div id="submit-button"></div>
      </form>
      <hr />
      <!--
      <div class="invoice_table_buttons" style="margin-bottom: 20px">
        <button id="bill_detail" class="button" @click="back" name="back">
          {{ $t("messages.button.back") }}
        </button>
      </div>
      -->
    </div>
    <div>
      <!-- <iframe
        :src="challangeUrl"
        width="600"
        height="400"
        title="Challange Screen"
      ></iframe> -->
      <iframe
        id="hidden_iframe"
        name="hidden_iframe"
        :class="{ showIframe: challageRequired, hideIframe: !challageRequired }"
      ></iframe>
    </div>
  </div>
</template>
<script>
import {
  checkVersion,
  initiateAuthentication,
  getGPChallangeResult,
} from "../../services/service.js";
import LoadingSpinner from "./LoadingSpinner.vue";
// import  { handleChallengeNotification } from "globalpayments-3ds"
export default {
  name: "GlobalPaymentCCPage",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      cardForm: "",
      payRef: "",
      totalAmout: 0,
      method: "",
      currency: "",
      routerQueryParams: {},
      challageRequired: false,
      challangeUrl: "",
      ccFormError: "",
      cardError: false,
      cardExpirationError: false,
      cvvError: false,
      hiddenIframe: "",
      checkResultPayload: {},
      showLoading: false,
    };
  },
  mounted() {
    this.payRef = sessionStorage.getItem("payRef");
    const billPay = JSON.parse(sessionStorage.getItem("billPay"));
    billPay.forEach((bill) => {
      if (bill.payFlag && bill.payAmount > 0) {
        this.totalAmout += Number(bill.payAmount);
      } else if (!bill.payFlag && bill.dueAmount < 1) {
        this.totalAmout += Number(bill.payAmount);
      } else if (bill.amount > 0) {
        this.totalAmout += Number(bill.amount);
      }
    });
    this.routerQueryParams = JSON.parse(
      sessionStorage.getItem("routerQueryParams")
    );
    this.currency = this.routerQueryParams.currency;

    // const ccElement = document.getElementById("credit-card");
    // ccElement.remove();

    // const ccNewElement = document.createElement("div");
    // ccNewElement.id = "credit-card";
    // const ccParentElement = document.getElementById("cc-parent-element");
    // ccParentElement.appendChild(ccNewElement);

    // this.cardForm = window.GlobalPayments.creditCard.form("#credit-card", {
    //   style: "gp-default",
    //   language: "zh",
    // });
    this.cardForm = window.GlobalPayments.ui.form({
      fields: {
        "card-number": {
          placeholder: "•••• •••• •••• ••••",
          target: "#card-number",
        },
        "card-expiration": {
          placeholder: "MM / YYYY",
          target: "#card-expiration",
        },
        "card-cvv": {
          placeholder: "•••",
          target: "#card-cvv",
        },
        "card-holder-name": {
          placeholder: "Jane Smith",
          target: "#card-holder-name",
        },
        submit: {
          target: "#submit-button",
          value: "Submit",
          text: "Submit",
        },
      },
      styles: {
        // add custom CSS styling
        //adding a radius on all text inputs
        "input[type=text]": {
          "margin-bottom": "20px",
          padding: "12px",
          border: "1px solid #ccc",
          "border-radius": "10px",
          width: "100%",
        },
        //adding focus on the fields when selected
        "input[type=text]:focus-visible, input[type=tel]:focus-visible": {
          outline: "none !important",
          border: "1px solid #71C5E8",
          "box-shadow": "0 0 4px 0 #71C5E8 inset",
        },
        //adding a radius on all number inputs
        "input[type=tel]": {
          "margin-bottom": "20px",
          padding: "12px",
          border: "1px solid #ccc",
          "border-radius": "10px",
        },
        //adding focus on the fields when selected
        "button[type=button]:focus-visible": {
          "background-color": "#4d148c",
          outline: "none !important",
          border: "1px solid gray",
          "box-shadow": "0 -1px 4px 0 gray inset",
        },
        //adding styling to the submit button
        "button[type=button]": {
          "background-color": "#4d148c",
          color: "white",
          padding: "12px",
          margin: "10px",
          border: "none",
          "border-radius": "10px",
          cursor: "pointer",
          "font-size": "17px",
        },
        //adding hover color
        "button[type=button]:hover": {
          "background-color": "#4d148c",
        },
      },
    });
    const sessionToken = sessionStorage.getItem("GPSessionStorage");
    this.configGlobalPayments(sessionToken);
    // method to notify that hosted fields have been initialized
    this.cardForm.ready(() => {
      //TODO: Add your successful message
      const CardEl = document.getElementsByName("card-number");
      CardEl[0].style.width = "100%";
      const CardEXEl = document.getElementsByName("card-expiration");
      CardEXEl[0].style.width = "100%";
      const CardCVVEl = document.getElementsByName("card-cvv");
      CardCVVEl[0].style.width = "100%";
      const nameEl = document.getElementsByName("card-holder-name");
      nameEl[0].style.width = "100%";
      const buttonEl = document.getElementsByName("submit");
      buttonEl[0].style["min-width"] = "100px";
      buttonEl[0].style.width = "15%";
      buttonEl[0].value = "Submit";
      this.cardForm.addStylesheet({
        "#secure-payment-field[type=button]": {
          "pointer-events": "unset",
          cursor: "pointer",
          "background-color": "#4d148c",
          color: "white",
          "padding": "5px 0 5px 0",
          width: "50%",
          "border-radius": "0px",
          flex: "none",
          "font-size": "14px",
        },
      });
    });
    this.cardForm.on("token-success", (resp) => {
      // add payment token to form as a hidden input
      this.showLoading = true;
      this.cardError = false;
      this.cardExpirationError = false;
      this.cvvError =false;
      this.ccFormError = false;
      this.response = resp;
      this.checkVersionCall(resp);
      const token = document.createElement("input");
      token.type = "hidden";
      token.name = "payment-reference";
      token.value = resp.paymentReference;
      token.id = "payment-token";
      const form = document.getElementById("payment-form");
      form.appendChild(token);
      //disabling the button
       this.cardForm.addStylesheet({
        "#secure-payment-field[name=cardNumber]": {
          "border-color": "#ccc"
        },
        "#secure-payment-field[class=card-expiration]": {
          "border-color": "#ccc"
        },
        "#secure-payment-field[placeholder=•••]": {
          "border-color": "#ccc"
        },
        "#secure-payment-field[type=button]": {
          "pointer-events": "none",
          cursor: "not-allowed",
          "background-color": "grey",
          color: "#4d148c",
        },
        });
      this.onSubmit();
      //   form.submit(function (e) {
      //     e.preventDefault();
      //   });
    });
    // add error handling if token generation is not successful
    this.cardForm.on("token-error", (resp) => {
      //enabling the button
      this.cardError = false;
      this.cardExpirationError = false;
      this.cvvError =false;
      this.ccFormError = false;
      this.cardForm.addStylesheet({
        "#secure-payment-field[name=cardNumber]": {
          "border-color": "#ccc"
        },
        "#secure-payment-field[class=card-expiration]": {
          "border-color": "#ccc"
        },
        "#secure-payment-field[placeholder=•••]": {
          "border-color": "#ccc"
        },
        "#secure-payment-field[type=button]": {
          "pointer-events": "unset",
          cursor: "pointer",
          "background-color": "#4d148c",
          color: "white",
        },
        });
      if (Object.entries(resp).length > 0)
        this.setCCFormError(resp?.reasons[0]?.message);
      else this.setCCFormError("expiry");
      // TODO: Add your error handling
    });
    // field-level event handlers. example:
    this.cardForm.on("card-number", "register", () => {
      console.log("Registration of Card Number occurred");
    });

    this.hiddenIframe = window.open("", "hidden_iframe");
    // Add Event Listerner
    // Called sometime after postMessage is called
    const challengeIframe = document.getElementById("hidden_iframe");
    window.addEventListener("message", (event) => {
      // Do we trust the sender of this message?
      // Verify the origin.
      if (event.origin !== process.env.VUE_APP_SERVER_END_POINT) {
        return;
      }
      // Verify the transactionID. TODO.
      if (event.data !== this.checkResultPayload?.sessionFieldValue) {
        return;
      }
      challengeIframe.style.display = "none";

      // Proceed with the remaining steps.
      this.getChallangeResult(this.checkResultPayload);
      this.showLoading = true;
    });
  },
  methods: {
    // configuring Hosted Fields
    configGlobalPayments(accessToken) {
      window.GlobalPayments.configure({
        accessToken: accessToken,
        apiVersion: process.env.VUE_APP_GP_VERSION,
        env: process.env.VUE_APP_GP_ENV
      });
    },
    back: function () {
      this.$router.back();
    },
    getChallangeResult(res) {
      const payload = {
        amount: res.amount,
        currency: res.currency,
        country: res.country,
        reference: res.reference,
        authenticationId: res.authenticationId,
        paymentToken: res.paymentToken,
      };
      getGPChallangeResult(this.$countryCode, payload)
        .then((response) => {
          this.showLoading = false;
          this.navigateToStatusPage(
            response.uiPaymentStatus,
            response.reference
          );
        })
        .catch((err) => {
          this.showLoading = false;
          console.error(err);
          this.$router.push({
            name: "ErrorInfo",
          });
        });
    },
    checkVersionCall(tokenRes) {
      const payload = {
        amount: Number(this.totalAmout.toFixed(2)),
        currency: this.routerQueryParams.currency,
        country: this.routerQueryParams.countryCode,
        reference: sessionStorage.getItem("payRef"),
        customerName: tokenRes.details?.cardholderName,
        paymentToken: tokenRes.paymentReference,
      };
      this.method = this.routerQueryParams.method;
      checkVersion(this.$countryCode, payload)
        .then((res) => {
          if (res.uiPaymentStatus === "AVAILABLE") {
            this.initiateAuthenticationCall(res);
          } else if (
            res.uiPaymentStatus === "PAYMENT_SUCCESS" ||
            res.uiPaymentStatus === "PAYMENT_FAIL"
          ) {
            this.navigateToStatusPage(res.uiPaymentStatus, res.reference);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({
            name: "ErrorInfo",
          });
        });
    },
    initiateAuthenticationCall(data) {
      const payload = {
        amount: data.amount,
        currency: data.currency,
        reference: data.reference,
        country: data.country,
        authenticationId: data.authenticationId,
        paymentToken: data.paymentToken,
        browserCompStatus: "YES",
      };
      initiateAuthentication(this.$countryCode, payload)
        .then((res) => {
          if (
            res.uiPaymentStatus === "PAYMENT_SUCCESS" ||
            res.uiPaymentStatus === "PAYMENT_FAIL"
          ) {
            this.navigateToStatusPage(res.uiPaymentStatus, res.reference);
          } else if (res.uiPaymentStatus === "CHALLENGE_REQUIRED") {
            this.challangeUrl = res.challengeUrl;
            this.challageRequired = true;
            this.createChallangeScreen(res);
            // this.redirectToChallangeScreen(res);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({
            name: "ErrorInfo",
          });
        });
    },
    redirectToChallangeScreen(res) {
      var method = "POST";
      var path = res.challengeUrl;

      var form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute("action", path);
      // Add form data
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", res.messageType);
      hiddenField.setAttribute("value", res.challengeValue);
      form.appendChild(hiddenField);

      var hiddenField_termURL = document.createElement("input");
      hiddenField_termURL.setAttribute("type", "hidden");
      hiddenField_termURL.setAttribute("name", "TermUrl");
      hiddenField_termURL.setAttribute("value", res.challengeNotificationUrl);
      form.appendChild(hiddenField_termURL);

      var hiddenField_encodeValue = document.createElement("input");
      hiddenField_encodeValue.setAttribute("type", "hidden");
      hiddenField_encodeValue.setAttribute("name", res.sessionFieldName);
      hiddenField_encodeValue.setAttribute("value", res.sessionFieldValue);
      form.appendChild(hiddenField_encodeValue);
      document.body.appendChild(form);
      form.submit();
    },
    createChallangeScreen(res) {
      let form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", res.challengeUrl);
      form.setAttribute("target", "hidden_iframe");

      let messageType = document.createElement("input");
      messageType.setAttribute("type", "hidden");
      messageType.setAttribute("name", res.messageType);
      messageType.setAttribute("value", res.challengeValue);
      form.appendChild(messageType);

      let termUrl = document.createElement("input");
      termUrl.setAttribute("type", "hidden");
      termUrl.setAttribute("name", "TermUrl");
      termUrl.setAttribute("value", res.challengeNotificationUrl);
      form.appendChild(termUrl);

      let sessionFieldName = document.createElement("input");
      sessionFieldName.setAttribute("type", "hidden");
      sessionFieldName.setAttribute("name", res.sessionFieldName);
      sessionFieldName.setAttribute("value", res.sessionFieldValue);
      form.appendChild(sessionFieldName);

      document.body.appendChild(form);
      this.checkResultPayload = {
        amount: res.amount,
        currency: res.currency,
        country: res.country,
        reference: res.reference,
        authenticationId: res.authenticationId,
        paymentToken: res.paymentToken,
        sessionFieldValue: res.sessionFieldValue,
      };
      this.showLoading = false;
      form.submit();
    },
    navigateToStatusPage(status, payRef, type = "invoice") {
      // const URL = window.location.protocol + "//" + window.location.host;
      const awbMethods = ["AQ", "AM","AC"];
      if (awbMethods.includes(this.routerQueryParams.method)) {
        type = "awb";
      }
      let navigateURL = "";
      if (type === "awb") {
        if (status === "PAYMENT_SUCCESS") {
          navigateURL = `/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/awb/success?payRef=${payRef}`;
        } else {
          navigateURL = `/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/awb/fail?payRef=${payRef}`;
        }
      } else {
        if (status === "PAYMENT_SUCCESS") {
          navigateURL = `/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/success?payRef=${payRef}`;
        } else {
          navigateURL = `/QRpay/${this.$countryCode}/${this.$i18n.locale}/PaymentStatus/fail?payRef=${payRef}`;
        }
      }
      this.showLoading = false;
      this.$router.push(navigateURL);
    },
    setCCFormError(error) {
      if (
        error.includes("card number") ||
        error.includes("Card number") ||
        error.includes(": number")
      ) {
        this.cardError = true;
        this.cardForm.addStylesheet({
        "#secure-payment-field[name=cardNumber]": {
          "border-color": "red"
        },
        });
      } else if (error.includes("expiry")) {
        this.cardExpirationError = true;
        this.cardForm.addStylesheet({
        "#secure-payment-field[class=card-expiration]": {
          "border-color": "red"
        },
        });
      } else if (error.includes("cvn") || error.includes("cvv")) {
        this.cvvError = true;
        this.cardForm.addStylesheet({
        "#secure-payment-field[placeholder=•••]": {
          "border-color": "red"
        },
        });
      } else {
        this.ccFormError = true;
      }
    },
  },
};
</script>
<style scoped>
.card-header-label{
  display: flex;
  align-items: center;
}
.card-header-section {
  display: flex;
}
.card-header-section p {
  width: 75%;
}
.log-section{
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.card-logo {
  flex-direction: row;
  margin-left: 60px;
}
.payment-info p {
  margin-bottom: 0 !important;
}
.required-info {
  margin-top:10px
}
.visa img {
  padding-top: 6px;
  padding-bottom: 10px;
}
.CC-card_holder {
  text-align: left;
  color: #000;
}
.CC-card_holder p {
  margin: 0 0 10px;
}
.CC-card_holder h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
.CC-card_holder .button {
  background-color: #4d148c;
  color: #fff;
  margin-right: 5px;
}
form {
  margin: auto;
  /* width: 360px;
  background-color: rgb(235, 240, 242); */
  width: 100%;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
}
#secure-payment-field {
  width: 100%;
}

#payment-form .date-cvv {
  display: flex;
}
#payment-form .cc-csv {
  margin-left: 10px;
}
#payment-form .cc-expiration,
.cc-csv {
  width: 100%;
}
.error_msg {
  color: red;
}
.showIframe {
  width: 600px;
  height: 400px;
}
.hideIframe {
  width: 0;
  height: 0;
}
@media (max-width: 768px) {
  #payment-form .date-cvv {
    display: block;
  }
  #payment-form .cc-csv {
    margin-left: 0px;
  }
  .showIframe {
    width: 100%;
    height: 425px;
  }
}
@media (max-width: 600px) {
  .card-logo {
    margin-left: 20px;
  }
  .visa img{
    width: 45px;
    height: 30px;
  }
  .mastercard img{
    width: 50px;
    height: 35px;
  }
}
@media (max-width: 300px) {
  .card-logo{
    margin-left: 5px;
  }
}
</style>
